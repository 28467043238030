<template>
  <router-view />
</template>

<script>
import { isTokenExpired } from './utility/jwtUtils';
export default {
  
  name: 'App',
  components: {
  },
  mounted() {
    this.checkToken();
    setInterval(this.checkToken, 60000);
  },
  methods: {
    checkToken() {
      console.log("checking token")
      const token = localStorage.getItem('adminToken');
      if(token){
        isTokenExpired(token);
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

.productListContainer{
  width: 80%;
  margin: auto;
}

</style>

