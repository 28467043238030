import { createRouter, createWebHistory } from 'vue-router';
import AdminDashboard from '@/components/admin/AdminDashboard.vue';
import AdminLogin from '@/components/admin/AdminLogin.vue';
import ProductEdit from '@/components/productcs/ProductEdit.vue';
import AddNewProduct from '@/components/productcs/AddNewProduct.vue';
import OrderEditComponent from '@/components/orders/OrderEditComponent.vue';
import UserDetails from '@/components/users/UserDetails.vue';
const routes = [
  {
    path: '/admin',
    name: 'AdminDashboard',
    component: AdminDashboard,
    meta: { requiresAdmin: true }, // Protect this route
  },
  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: AdminLogin,
  },
  {
    path: '/product/edit/:id',
    name: 'ProductEdit',
    component: ProductEdit,
    props: true,
    meta: { requiresAdmin: true },
  },
  {
    path: '/orders/:id/edit',
    name: 'OrderEdit',
    component: OrderEditComponent,
    props: true,
    meta: { requiresAdmin: true },
  },
  {
    path: '/product/new',
    name: 'NewProduct',
    component: AddNewProduct,
    meta: { requiresAdmin: true}
  },
  {
    path: '/user/:id/details',
    name: 'UserDetails',
    component: UserDetails,
    props: true,
    meta: { requiresAdmin: true}
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/admin/login', // Redirect any unknown route to login
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const adminToken = localStorage.getItem('adminToken');
  
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (!adminToken) {
      next({
        path: '/admin/login',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.name === 'AdminLogin' && adminToken) {
    next({ path: '/admin' }); // If already logged in, redirect to dashboard
  } else {
    next();
  }
});

export default router;
