<template>
  <div class="product-add-container">
    <div class="col-2 offset-1 button-container">
      <router-link :to="{ name: 'AdminDashboard' }" class="styled-button">Atgal</router-link>
    </div>

    <div class="product-form-wrapper">
      <h2 class="product-title">Pridėti Naują Produktą</h2>

      <!-- Fixed-height message container -->
      <div class="message-container">
        <div v-if="successMessage" class="alert alert-success">
          {{ successMessage }}
        </div>
        <div v-if="errorMessage" class="alert alert-danger">
          {{ errorMessage }}
        </div>
      </div>

      <div style="margin-bottom: 1rem;">
        <label class="main-image-label">
          <span>Produkto matomas</span>
          <input type="checkbox" v-model="formData.isVisible" />
        </label>
      </div>

      <!-- Subcategory Selection Prompt -->
      <div class="form-group">
        <label for="subCategoryPrompt" class="form-label">Pasirinkite subkategoriją, kad užpildytumėte laukus</label>
        <select v-model="selectedSubCategory" id="subCategoryPrompt" class="form-control" @change="fetchPresets">
          <option disabled value="">Pasirinkite subkategoriją</option>
          <option v-for="subcategory in subCategories" :key="subcategory.id" :value="subcategory.id">
            {{ subcategory.name }}
          </option>
        </select>
      </div>

      <!-- Product Form (Shown only after subcategory is selected) -->
      <form v-if="formVisible" @submit.prevent="addProduct" class="add-form">

        <!-- Source Link -->
        <div class="form-group">
          <label for="source_link" class="form-label">Produkto šaltinis (nuoroda)</label>
          <input v-model="formData.source_link" type="text" id="source_link" class="form-control" required />
        </div>

        <!-- Product Name -->
        <div class="form-group">
          <label for="name" class="form-label">Produkto pavadinimas</label>
          <input v-model="formData.name" type="text" id="name" class="form-control" required />
        </div>

        <!-- Price, MSRP, Cost Price -->
        <div class="form-group prices-group">
          <div class="price-item">
            <label for="price" class="form-label">Kaina</label>
            <input v-model="formData.price" type="number" id="price" class="form-control" step="0.01" required />
          </div>
          <div class="price-item">
            <label for="msrp" class="form-label">Rekomenduojama kaina</label>
            <input v-model="formData.MSRP" type="number" id="msrp" class="form-control" step="0.01" required />
          </div>
          <div class="price-item">
            <label for="cost_price" class="form-label">Savikaina</label>
            <input v-model="formData.cost_price" type="number" id="cost_price" class="form-control" step="0.01"
              required />
          </div>
        </div>

        <!-- Condition -->
        <div class="form-group">
          <label for="condition" class="form-label">Būklė</label>
          <select v-model="formData.condition" id="condition" class="form-control" required>
            <option disabled value="">Pasirinkite būklę</option>
            <option v-for="condition in conditions" :key="condition.id" :value="condition.id">
              {{ condition.name }}
            </option>
          </select>
        </div>

        <!-- Description -->
        <div class="form-group">
          <label for="description" class="form-label">Aprašymas</label>
          <textarea v-model="formData.mainDescription" id="description" class="form-control" rows="5"></textarea>
        </div>

        <!-- General Details -->
        <div class="form-group">
          <label for="generalDetails" class="form-label">Bendros Detalės</label>
          <div class="char-table-data">
            <div v-for="(item, index) in formData.generalDetails" :key="index" class="char-table-item">
              <input v-model="item.feature" class="form-control" placeholder="Ypatybė" />
              <input v-model="item.value" class="form-control" placeholder="Vertė" />
              <button type="button" @click="removeCharacteristic(index)" class="btn-delete">Pašalinti</button>
            </div>
            <button type="button" @click="addCharacteristic" class="btn-add">Pridėti Ypatybę</button>
          </div>
        </div>

        <!-- Attributes -->
        <div class="form-group">
          <label for="attributes" class="form-label">Atributai</label>
          <div class="char-table-data">
            <div v-for="(item, index) in formData.attributes" :key="index" class="char-table-item">
              <input v-model="item.attribute_name" class="form-control" placeholder="Atributo pavadinimas" />
              <input v-model="item.attribute_value" class="form-control" placeholder="Atributo vertė" />
              <button type="button" @click="removeSpecification(index)" class="btn-delete">Pašalinti</button>
            </div>
            <button type="button" @click="addSpecification" class="btn-add">Pridėti Atributą</button>
          </div>
        </div>

        <!-- Images -->
        <div class="form-group">
          <label for="images" class="form-label">Nuotraukos</label>
          <div class="char-table-data">
            <div v-for="(image, index) in formData.images" :key="index" class="char-table-item">
              <input v-model="image.image_url" class="form-control" placeholder="Nuotraukos URL" />
              <label class="main-image-label">
                <span>Pagrindinė</span>
                <input type="checkbox" v-model="image.is_main_image" />
              </label>
              <button type="button" @click="removeImage(index)" class="btn-delete">Pašalinti</button>
            </div>
            <button type="button" @click="addImage" class="btn-add">Pridėti Nuotrauką</button>
          </div>
        </div>

        <button type="submit" class="btn-submit">Pridėti Produktą</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { isTokenExpired } from "@/utility/jwtUtils";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  name: "AddNewProduct",
  data() {
    return {
      selectedSubCategory: null,
      formData: {
        name: "",
        price: "",
        MSRP: "",
        cost_price: "",
        condition: null,
        subCategory: null,
        mainDescription: "",
        generalDetails: [],
        attributes: [],
        images: [],
        source_link: "",
        isVisible: false,
      },
      conditions: [],
      subCategories: [],
      formVisible: false,
      successMessage: null,
      errorMessage: null,
    };
  },
  methods: {
    async fetchConditions() {
      try {
        const response = await axios.get(`${API_BASE_URL}/conditions`);
        this.conditions = response.data;
      } catch (error) {
        console.error("Klaida gaunant būkles:", error);
      }
    },
    async fetchSubCategories() {
      try {
        const response = await axios.get(`${API_BASE_URL}/subcategories`);
        this.subCategories = response.data;
      } catch (error) {
        console.error("Klaida gaunant subkategorijas:", error);
      }
    },
    async fetchPresets() {
      if (!this.selectedSubCategory) return;

      try {
        const response = await axios.get(`${API_BASE_URL}/subcategory/${this.selectedSubCategory}/presets`);
        const presets = response.data;

        this.formData.generalDetails = presets
          .filter((preset) => preset.fieldType === "generalDetail")
          .map((preset) => ({ feature: preset.fieldName, value: preset.defaultValue }));

        this.formData.attributes = presets
          .filter((preset) => preset.fieldType === "attribute")
          .map((preset) => ({ attribute_name: preset.fieldName, attribute_value: preset.defaultValue }));

        this.formData.subCategory = this.selectedSubCategory;
        this.formVisible = true;
      } catch (error) {
        this.errorMessage = "Nepavyko užkrauti pasirinktos subkategorijos duomenų";
      }
    },
    addProduct() {
      const hasEmptyGeneralDetail = this.formData.generalDetails.some(
        (item) => !item.feature || !item.value
      );
      const hasEmptyAttribute = this.formData.attributes.some(
        (item) => !item.attribute_name || !item.attribute_value
      );

      if (hasEmptyGeneralDetail || hasEmptyAttribute) {
        this.errorMessage = "Prašome užpildyti visus techninius ir atributų laukus.";
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.clearMessages();
        return;
      }

      const payload = {
        name: this.formData.name,
        price: this.formData.price,
        MSRP: this.formData.MSRP,
        cost_price: this.formData.cost_price,
        mainDescription: this.formData.mainDescription,
        condition: this.formData.condition,
        subCategory: this.formData.subCategory,
        generalDetails: this.formData.generalDetails,
        attributes: this.formData.attributes,
        images: this.formData.images,
        source_link: this.formData.source_link,
        status: this.formData.isVisible ? 1 : 3,
      };

      const token = localStorage.getItem("adminToken");
      if (isTokenExpired(token)) {
        return;
      }

      axios
        .post(`${API_BASE_URL}/product/create`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.successMessage = "Produktas sėkmingai pridėtas!";
          this.clearMessages();
          this.resetForm();
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch((error) => {
          this.errorMessage = "Klaida pridedant produktą. Bandykite dar kartą. " + error;
          this.clearMessages();
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    clearMessages() {
      setTimeout(() => {
        this.successMessage = null;
        this.errorMessage = null;
      }, 3000);
    },
    resetForm() {
      this.formData = {
        name: "",
        price: "",
        MSRP: "",
        cost_price: "",
        condition: null,
        subCategory: null,
        mainDescription: "",
        generalDetails: [],
        attributes: [],
        images: [],
        source_link: "",
        isVisible: false,
      };
      this.formVisible = false;
      this.selectedSubCategory = null;
    },
    addCharacteristic() {
      this.formData.generalDetails.push({ feature: "", value: "" });
    },
    addSpecification() {
      this.formData.attributes.push({ attribute_name: "", attribute_value: "" });
    },
    removeCharacteristic(index) {
      this.formData.generalDetails.splice(index, 1);
    },
    removeSpecification(index) {
      this.formData.attributes.splice(index, 1);
    },
    addImage() {
      this.formData.images.push({ image_url: "", is_main_image: false });
    },
    removeImage(index) {
      this.formData.images.splice(index, 1);
    },
  },
  created() {
    this.fetchConditions();
    this.fetchSubCategories();
  },
};
</script>

<style scoped>
.product-add-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  overflow-x: hidden;
}

.product-form-wrapper {
  max-width: 800px;
  width: 100%;
  background: #f4f7f9;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.product-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 2rem;
}

/* Fixed height for the message container to prevent content displacement */
.message-container {
  min-height: 50px;
}

.alert {
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.alert-success {
  background-color: #d4edda;
  color: #155724;
}

.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-label {
  font-size: 1rem;
  color: #495057;
  font-weight: 500;
}

.form-control {
  width: 100%;
  padding: 0.75rem;
  border-radius: 5px;
  border: 1px solid #ced4da;
}

.prices-group {
  display: flex;
  justify-content: space-between;
}

.price-item {
  width: 29%;
}

.char-table-data {
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #dee2e6;
  margin-bottom: 1rem;
}

.char-table-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
}

.btn-add,
.btn-delete {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  font-size: 0.875rem;
}

.btn-add {
  background-color: #5bc0de;
  color: #ffffff;
}

.btn-delete {
  background-color: #dc3545;
  color: #ffffff;
}

.btn-submit {
  background-color: #28a745;
  color: #ffffff;
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  width: 100%;
  transition: background-color 0.3s ease, transform 0.2s;
}

.btn-submit:hover {
  background-color: #218838;
}

.btn-submit:active {
  transform: scale(0.98);
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.styled-button {
  padding: 10px 20px;
  background-color: #2cb6d2;
  color: #ffffff;
  font-weight: bold;
  font-size: 1em;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: background-color 0.3s, transform 0.2s;
}

.styled-button:hover {
  background-color: #2396ae;
  transform: translateY(-2px);
}
</style>
