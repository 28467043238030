<template>
  <div class="conditions-component">
    <h2>Conditions</h2>
    <button @click="addCondition" class="add-condition-btn">
      Add New Condition
    </button>

    <!-- Notification Messages -->
    <div v-if="message" :class="['notification', message.type]">
      {{ message.text }}
    </div>

    <!-- Loading Indicator -->
    <div v-if="loading" class="loading">Loading conditions...</div>

    <!-- Conditions Table -->
    <div v-else class="conditions-table">
      <div
        v-for="condition in conditions"
        :key="condition.id"
        class="condition-group"
      >
        <div class="condition-header">
          <div style="display: flex; flex-direction: row">
            <div style="width: 20rem">
              <span
                v-if="editingConditionId !== condition.id"
                class="condition-name"
                >{{ condition.name }}</span
              >
              <input
                v-if="editingConditionId === condition.id"
                v-model="conditionForm.name"
                class="edit-field"
                placeholder="Condition Name"
              />
            </div>
          </div>
          <div style="width: 30rem; text-align: left">
            <span
              v-if="editingConditionId !== condition.id"
              class="condition-description"
              >{{ condition.description }}</span
            >
            <textarea
              v-if="editingConditionId === condition.id"
              v-model="conditionForm.description"
              class="edit-field"
              placeholder="Description"
              rows="5"
              style="width: 30rem"
            />
          </div>
          <div class="condition-actions">
            <button
              v-if="editingConditionId === condition.id"
              @click="saveCondition(condition)"
            >
              Save
            </button>
            <button
              v-if="editingConditionId === condition.id"
              @click="cancelEditing"
            >
              Cancel
            </button>
            <button v-else @click.stop="editCondition(condition)">Edit</button>
            <button @click.stop="confirmDeleteCondition(condition)">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Confirmation Modal for Deleting Condition -->
    <div v-if="showDeleteModal" class="modal-backdrop">
      <div class="modal">
        <div class="modal-content">
          <p>
            Are you sure you want to delete the condition:
            <strong>{{ conditionToDelete?.name }}</strong
            >?
          </p>
          <div class="modal-btn-container">
            <button @click="deleteConditionConfirmed">Yes</button>
            <button @click="closeDeleteModal">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ConditionsComponent",
  data() {
    return {
      conditions: [],
      editingConditionId: null,
      conditionForm: { id: null, name: "", description: "", isNew: false },
      loading: true,
      message: null,
      showDeleteModal: false,
      conditionToDelete: null,
    };
  },
  methods: {
    async fetchConditions() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/conditions`
        );
        this.conditions = response.data;
      } catch (error) {
        this.showMessage("Error loading conditions", "error");
      } finally {
        this.loading = false;
      }
    },
    addCondition() {
      const newCondition = {
        id: Date.now(),
        name: "",
        description: "",
        isNew: true,
      };
      this.conditions.push(newCondition);
      this.editingConditionId = newCondition.id;
      this.conditionForm = { ...newCondition };
    },
    editCondition(condition) {
      this.editingConditionId = condition.id;
      this.conditionForm = { ...condition, isNew: false };
    },
    async saveCondition(condition) {
      try {
        if (this.conditionForm.isNew) {
          const response = await axios.post(
            `${process.env.VUE_APP_API_BASE_URL}/condition/create`,
            {
              name: this.conditionForm.name,
              description: this.conditionForm.description,
            }
          );
          condition.id = response.data.id;
          condition.isNew = false;
        } else {
          await axios.put(
            `${process.env.VUE_APP_API_BASE_URL}/condition/update/${condition.id}`,
            {
              name: this.conditionForm.name,
              description: this.conditionForm.description,
            }
          );
        }

        condition.name = this.conditionForm.name;
        condition.description = this.conditionForm.description;
        this.editingConditionId = null;
        this.fetchConditions();
        this.showMessage("Condition saved successfully", "success");
      } catch (error) {
        this.showMessage("Error updating condition", "error");
      }
    },
    cancelEditing() {
      if (this.conditionForm.isNew) {
        this.conditions = this.conditions.filter(
          (c) => c.id !== this.conditionForm.id
        );
      }
      this.editingConditionId = null;
    },
    confirmDeleteCondition(condition) {
      this.conditionToDelete = condition;
      this.showDeleteModal = true;
    },
    async deleteConditionConfirmed() {
      if (this.conditionToDelete) {
        try {
          await axios.delete(
            `${process.env.VUE_APP_API_BASE_URL}/condition/delete/${this.conditionToDelete.id}`
          );
          this.conditions = this.conditions.filter(
            (c) => c.id !== this.conditionToDelete.id
          );
          this.showMessage("Condition deleted successfully", "success");
        } catch (error) {
          this.showMessage("Error deleting condition", "error");
        } finally {
          this.closeDeleteModal();
        }
      }
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
      this.conditionToDelete = null;
    },
    showMessage(text, type) {
      this.message = { text, type };
      setTimeout(() => {
        this.message = null;
      }, 3000);
    },
  },
  mounted() {
    this.fetchConditions();
  },
};
</script>

<style scoped>
.conditions-component {
  width: 100%;
  font-family: "Inter", sans-serif;
}

.conditions-table {
  width: 100%;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.loading {
  padding: 20px;
  text-align: center;
  color: #2cb6d2;
}

.notification {
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  text-align: center;
}

.notification.success {
  background-color: #d4edda;
  color: #155724;
}

.notification.error {
  background-color: #f8d7da;
  color: #721c24;
}

.condition-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  color: #000;
  background-color: #89ebff;
  margin-bottom: 10px;
  border-radius: 5px;
}

.edit-field {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.condition-actions button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #fff;
  color: #2cb6d2;
  border: 1px solid #2cb6d2;
  border-radius: 5px;
  cursor: pointer;
}

.add-condition-btn {
  background-color: #2cb6d2;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.modal {
  width: 100%;
  max-width: 400px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  position: absolute;
  display: block;
  top: 30%;
  left: 42%;
  height: 170px;
}

.modal-content {
  padding: 20px;
  text-align: center;
}

.modal-btn-container {
  display: flex;
  justify-content: space-around;
  gap: 10px;
}

.modal-btn-container button {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.modal-btn-container .confirm-btn {
  background-color: #dc3545;
  color: #fff;
}

.modal-btn-container .cancel-btn {
  background-color: #6c757d;
  color: #fff;
}
</style>
